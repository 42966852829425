import {
  GET_ALL_FAMILIES_SUCCESS,
  GET_ALL_LINES_ERROR,
  GET_ALL_LINES_START, GET_ALL_LINES_SUCCESS
} from '../types';

export default function (state, action) {
  switch (action.type) {
    case GET_ALL_LINES_START:
      return {
        ...state,
        quantity: 0,
        lines: [],
        loading: true,
        error: null
      };
    case GET_ALL_LINES_SUCCESS:
      return {
        ...state,
        quantity: action.results.quantity,
        lines: action.results.productlines,
        loading: false,
        error: null
      };
    case GET_ALL_LINES_ERROR:
      return {
        ...state,
        quantity: 0,
        lines: [],
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
