import {
  GET_ORGANIZATION_ERROR,
  GET_ORGANIZATION_START,
  GET_ORGANIZATION_SUCCESS
} from '../../types';

export default function (state, action) {
  switch (action.type) {
    case GET_ORGANIZATION_START:
      return {
        ...state,
        quantity: 0,
        entries: [],
        loading: true,
        error: null
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        quantity: action.results.quantity,
        entries: action.results.entries,
        loading: false,
        error: null
      };
    case GET_ORGANIZATION_ERROR:
      return {
        ...state,
        quantity: 0,
        entries: [],
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
