import {takeLatest, put, call} from 'redux-saga/effects';
import {
  UPDATE_REGION_ERROR,
  UPDATE_REGION_START,
  UPDATE_REGION_SUCCESS
} from '../types';
import apiCall from '../../api';

export function* update({payload}) {
  try {
    const url = '/Region/update';
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      id: payload.id,
      name: payload.name,
      isoname: payload.isoname,
      isactive: payload.isActive
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: UPDATE_REGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: UPDATE_REGION_ERROR, error});
  }
}

export default function* updateRegion() {
  yield takeLatest(UPDATE_REGION_START, update);
}
