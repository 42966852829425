import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import Logo from './Logo';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TranslateIcon from '@material-ui/icons/Translate';

import { setLanguage } from '../redux/actions/language';

const MainNavbar = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [alias, setAlias] = useState('Español');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
    dispatch(setLanguage(alias === 'Español' ? 'es' : 'en'))
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log(alias);
  };

  return (
    <AppBar
      elevation={0}
      {...props}
    >
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton
            color="inherit"
            onClick={handleClick}
            direction="row"
            container
            alignContent="center"
            alignItems="center"
          >
            <TranslateIcon style={{ color: 'rgb(252, 203, 9)', marginRight: 1 }} />
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              style={{ color: 'rgb(252, 203, 9)' }}
            >
              {alias}
            </Typography>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '15ch',
              },
            }}
          >
            <MenuItem onClick={() => {
              handleClose();
              dispatch(setLanguage('es'))
              setAlias('Español');
            }}
            >
              Español
            </MenuItem>
            <MenuItem onClick={() => {
              handleClose();
              dispatch(setLanguage('en'))
              setAlias('English');
            }}
            >
              English
            </MenuItem>
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
};

export default MainNavbar;
