import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ORGANIZATIONS_ERROR,
  GET_ORGANIZATIONS_START,
  GET_ORGANIZATIONS_SUCCESS
} from '../types';
import apiCall from '../../api/apiGet';

export function* organizations({payload}) {
  try {
    const url = `/Organization/ListOrganizationByUser/${payload.username}`;
    const method = 'GET';
    const headers = {};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ORGANIZATIONS_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_ORGANIZATIONS_ERROR, error});
  }
}

export default function* getOrganizations() {
  yield takeLatest(GET_ORGANIZATIONS_START, organizations);
}
