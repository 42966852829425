import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_CHANNELS_START,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_ERROR,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getChannels({payload}) {
  try {
    const url = `/Channel/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_CHANNELS_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_CHANNELS_ERROR, error});
  }
}

export default function* channels() {
  yield takeLatest(GET_CHANNELS_START, getChannels);
}
