import {
  GET_ORGANIZATIONS_START,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_ERROR
} from '../types';

export default function (state, action) {
  switch (action.type) {
    case GET_ORGANIZATIONS_START:
      return {
        ...state,
        organizations: [],
        loading: true,
        error: null
      };
    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.results,
        loading: false,
        error: null
      };
    case GET_ORGANIZATIONS_ERROR:
      return {
        ...state,
        organizations: [],
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
