import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_SUBREGION_ERROR,
  CREATE_SUBREGION_START,
  CREATE_SUBREGION_SUCCESS,
} from '../../types';
import apiCall from '../../../api';

export function* create({payload}) {
  try {
    const url = '/SubRegion/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.name,
      regionid: payload.regionid,
      isactive : true
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_SUBREGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: CREATE_SUBREGION_ERROR, error});
  }
}

export default function* createSubregion() {
  yield takeLatest(CREATE_SUBREGION_START, create);
}
