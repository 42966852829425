import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_SUBREGION_ERROR,
  GET_ALL_SUBREGION_START,
  GET_ALL_SUBREGION_SUCCESS,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* subregions({payload}) {
  try {
    const url = '/SubRegion/GetList';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_SUBREGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_ALL_SUBREGION_ERROR, error});
  }
}

export default function* getSubregion() {
  yield takeLatest(GET_ALL_SUBREGION_START, subregions);
}
