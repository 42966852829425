import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_MERCHANDISE_START,
  GET_MERCHANDISE_SUCCESS,
  GET_MERCHANDISE_ERROR,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getMerchandise({payload}) {
  try {
    const url = `/Mercaderia/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_MERCHANDISE_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_MERCHANDISE_ERROR, error});
  }
}

export default function* merchandise() {
  yield takeLatest(GET_MERCHANDISE_START, getMerchandise);
}
