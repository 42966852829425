import { takeLatest, call, put } from 'redux-saga/effects';
import { POST_LOGIN_START, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR, LOGOUT } from '../types';

import apiCall from '../../api';

export function* postLogin({ payload }) {
  try {
    if (payload === 'LOGOUT') {
      yield put({type: LOGOUT})
    } else {
      const url = '/Login/LoginWeb';
      const method = 'POST';
      const data = payload;
      data.IDCompany = 380;
      console.log(data);
      const results = yield call(apiCall, url, method, data);
      yield put({ type: POST_LOGIN_SUCCESS, results: results.data });
    }
  } catch (error) {
    yield put({ type: POST_LOGIN_ERROR, error });
  }
}

export default function* login() {
  yield takeLatest(POST_LOGIN_START, postLogin);
}
