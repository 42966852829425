import { Navigate, Outlet } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductLines from './pages/ProductLines';
import ProductMerchandise from './pages/ProductMerchandise';
import ProductFamilies from './pages/ProductFamilies';
import LogisticsCustoms from './pages/LogisticsCustoms';
import LogisticsChannels from './pages/LogisticsChannels';
import LogisticsDeposits from './pages/LogisticsDeposits';
import LogisticsTerminal from "./pages/LogisticsTerminal";
import LogisticsContainer from "./pages/LogisticsContainer";
import LogisticsTransport from "./pages/LogisticsTransport";
import LogisticsTypeDocument from "./pages/LogisticsTypeDocument";
import LogisticsPorts from "./pages/Logistics/LogisticsPorts";
import Others from "./pages/Configuration/others";
import Warehouse from "./pages/Operative/OperativeWarehouse";
import Destination from "./pages/Operative/OperativeDestination";
import Driver from "./pages/Operative/OperativeDriver";
import OperativeTransport from "./pages/Operative/OperativeTransport";
import Subregion from "./pages/Configuration/Subregion"
import OperativeArea from "./pages/Configuration/OperativeArea"


const routes = (isLogged) => [
  {
    path: 'app',
    element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products/lines', element: <ProductLines /> },
      { path: 'products/families', element: <ProductFamilies /> },
      { path: 'products/merchandise', element: <ProductMerchandise /> },
      { path: 'logistics/customs', element: <LogisticsCustoms /> },
      { path: 'logistics/channels', element: <LogisticsChannels /> },
      { path: 'logistics/deposits', element: <LogisticsDeposits /> },
      { path: 'logistics/ports', element: <LogisticsPorts /> },
      { path: 'logistics/terminal', element: <LogisticsTerminal /> },
      { path: 'logistics/container', element: <LogisticsContainer /> },
      { path: 'logistics/Transports', element: <LogisticsTransport /> },
      { path: 'logistics/DocumentType', element: <LogisticsTypeDocument /> },
      { path: 'configuration/others', element: <Others /> },
      { path: 'operative/warehouse', element: <Warehouse /> },
      { path: 'operative/destination', element: <Destination /> },
      { path: 'operative/driver', element: <Driver /> },
      { path: 'operative/Transport', element: <OperativeTransport /> },
      { path: 'configuration/subregion', element: <Subregion /> },
      { path: 'configuration/operativearea', element: <OperativeArea /> },



      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !isLogged ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
