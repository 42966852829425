import {takeLatest, put, call} from 'redux-saga/effects';
import {
  UPDATE_TERMINAL_START, UPDATE_TERMINAL_ERROR, UPDATE_TERMINAL_SUCCESS,
} from '../types';
import apiCall from '../../api';

export function* update({payload}) {
  try {
    const url = '/PortTerminal/update';
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      id: payload.id,
      name: payload.name,
      isactive: payload.isactive
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: UPDATE_TERMINAL_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: UPDATE_TERMINAL_ERROR, error});
  }
}

export default function* updateTerminal() {
  yield takeLatest(UPDATE_TERMINAL_START, update);
}
