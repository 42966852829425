import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from '../reducers';
import sagas from '../sagas';


const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'login',
    'language',
    'getOrganizations',
    'lines',
    'createLine',
    'updateLine',
    'deleteLine',
    'families',
    'createFamilies',
    'updateFamilies',
    'deleteFamilies',
    'createCustoms',
    'updateCustoms',
    'deleteCustoms',
    'customs',
    'channels',
    'createChannels',
    'deleteChannels',
    'updateChannels',
    'fiscalwarehouse',
    'createFiscalWarehouse',
    'updateFiscalWarehouse',
    'terminal',
    'createTerminal',
    'updateTerminal',
    'containers',
    'createContainers',
    'updateContainers',
    'getLines',
    'getFamilies',
    'merchandise',
    'createMerchandise',
    'updateMerchandise',
    'transports',
    'createTransports',
    'updateTransports',
    'region',
    'getRegion',
    'createRegion',
    'updateRegion',
    'typeDocuments',
    'createTypeDocuments',
    'updateTypeDocuments',
    'port',
    'createPort',
    'updatePort',
    'warehouse',
    'createWarehouse',
    'updateWarehouse',
    'destination',
    'createDestination',
    'updateDestination',
    'driver',
    'createDriver',
    'updateDriver',
    'operativeTransport',
    'createOperativeTransport',
    'updateOperativeTransport',
    'subregion',
    'createSubregion',
    'updateSubregion',
    'getSubregion',
    'operativeArea',
    'createOperativeArea',
    'updateOperativeArea',
    'getOperativeArea',
    'getOrganizationTypes'




  ],
  timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose,
  ),
);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

store.persistor = persistor;

export {store, persistor};
