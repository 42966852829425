import {takeLatest, put, call} from 'redux-saga/effects';
import {
  UPDATE_TRANSPORT_START,
  UPDATE_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_ERROR, UPDATE_CONTAINER_START,
} from '../types';
import apiCall from '../../api';

export function* update({payload}) {
  try {
    const url = '/CamionType/update';
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token};
    console.log('LLEGA AL UPDATE')
    console.log(payload);
    const data = {
      id: payload.id,
      name: payload.name,
      capacidad: payload.capacidad,
      high: payload.high,
      width: payload.width,
      long: payload.long,
      high2: payload.high2,
      width2: payload.width2,
      long2: payload.long2,
      isActive: payload.isActive
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: UPDATE_TRANSPORT_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: UPDATE_TRANSPORT_ERROR, error});
  }
}


export default function* updateTransports() {
  yield takeLatest(UPDATE_TRANSPORT_START, update);
}
