import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_CUSTOMS_ERROR,
  GET_CUSTOMS_START,
  GET_CUSTOMS_SUCCESS,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getCustoms({payload}) {
  try {
    const url = `/Custom/ListCustom/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_CUSTOMS_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_CUSTOMS_ERROR, error});
  }
}

export default function* customs() {
  yield takeLatest(GET_CUSTOMS_START, getCustoms);
}
