import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_TYPEDOCUMENT_ERROR,
  CREATE_TYPEDOCUMENT_START,
  CREATE_TYPEDOCUMENT_SUCCESS,
} from '../types';
import apiCall from '../../api';

export function* create({payload}) {
  try {
    const url = '/DocumentType/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.name,
      isactive: true}
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_TYPEDOCUMENT_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: CREATE_TYPEDOCUMENT_ERROR, error});
  }
}

export default function* createTypeDocuments() {
  yield takeLatest(CREATE_TYPEDOCUMENT_START, create);
}

