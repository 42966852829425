import { SET_LANGUAGE_ERROR, SET_LANGUAGE_START, SET_LANGUAGE_SUCCESS } from '../types';

export default function (state, action) {
  switch (action.type) {
    case SET_LANGUAGE_START:
      return { ...state };
    case SET_LANGUAGE_SUCCESS:
      return {
        ...state, language: action.results,
      };
    case SET_LANGUAGE_ERROR:
      return { ...state, language: 'es', error: 'error' };
    default:
      return { ...state };
  }
}
