import { combineReducers } from 'redux';
import {REHYDRATE} from 'redux-persist/lib/constants';
import moment from "moment";

import { LOGOUT } from '../types';

import login from './login';
import language from './language';
import getOrganizations from './getOrganizations';
import lines from './lines';
import createLine from './createLine';
import updateLine from './updateLine';
import deleteLine from './deleteLine';
import families from './families';
import createFamilies from './createFamilies';
import updateFamilies from './updateFamilies';
import deleteFamilies from './deleteFamilies';
import createCustoms from "./createCustoms";
import updateCustoms from "./updateCustoms";
import deleteCustoms from "./deleteCustoms";
import customs from "./customs";
import channels from "./channels";
import createChannels from "./createChannels";
import deleteChannels from "./deleteChannels";
import updateChannels from "./updateChannels";
import fiscalwarehouse from "./fiscalwarehouse";
import createFiscalWarehouse from "./createFiscalWarehouse";
import updateFiscalWarehouse from "./updateFiscalWarehouse";
import terminal from "./terminal";
import createTerminal from "./createTerminal";
import updateTerminal from "./updateTerminal";
import containers from "./containers";
import createContainers from "./createContainers";
import updateContainers from "./updateContainers";
import getFamilies from "./getFamilies";
import getLines from "./getLines";
import merchandise from "./merchandise";
import createMerchandise from "./createMerchandise";
import updateMerchandise from "./updateMerchandise";
import transports from "./typeTransports";
import createTransports from "./createTransports";
import updateTransports from "./updateTransports";
import typeDocuments from "./typeDocuments";
import createTypeDocument from "./createTypeDocument";
import updateTypeDocument from "./updateTypeDocument";
import region from "./region";
import createRegion from "./createRegion";
import updateRegion from "./updateRegion";
import getRegion from "./getRegion";
import port from "./LogisticPort/port";
import createPort from "./LogisticPort/createPort";
import updatePort from "./LogisticPort/updatePort";
import getPorts from "./LogisticPort/getPorts";
import warehouse from "./OperativeWarehouse/warehouse";
import createWarehouse from "./OperativeWarehouse/createWarehouse";
import updateWarehouse from "./OperativeWarehouse/updateWarehouse";
import destination from "./OperativeDestination/destination";
import createDestination from "./OperativeDestination/createDestination";
import updateDestination from "./OperativeDestination/updateDestination";
import driver from "./OperativeDriver/driver";
import createDriver from "./OperativeDriver/createDriver";
import updateDriver from "./OperativeDriver/updateDriver";
import operativeTransport from "./OperativeTransport/operativeTransport";
import createOperativeTransport from "./OperativeTransport/createOperativeTransport";
import updateOperativeTransport from "./OperativeTransport/updateOperativeTransport";
import subregion from "./ConfigurationSubregion/subregion";
import createSubregion from "./ConfigurationSubregion/createSubregion";
import updateSubregion from "./ConfigurationSubregion/updateSubregion";
import getSubregion from "./ConfigurationSubregion/getSubregion";
import operativeArea from "./ConfigurationOperativeArea/operativeArea";
import createOperativeArea from "./ConfigurationOperativeArea/createOperativeArea";
import updateOperativeArea from "./ConfigurationOperativeArea/updateOperativeArea";
import getOperativeArea from "./ConfigurationOperativeArea/getOperativeArea";
import getOrganizationTypes from "./ConfigurationOrganizationTypes/getOrganizationTypes";
import organization from "./ConfigurationOrganization/organization";
import createOrganization from "./ConfigurationOrganization/createOrganization";
import updateOrganization from "./ConfigurationOrganization/updateOrganization";


const appReducer = combineReducers({
  login,
  language,
  getOrganizations,
  lines,
  createLine,
  updateLine,
  deleteLine,
  families,
  createFamilies,
  updateFamilies,
  deleteFamilies,
  customs,
  createCustoms,
  updateCustoms,
  deleteCustoms,
  channels,
  createChannels,
  deleteChannels,
  updateChannels,
  fiscalwarehouse,
  createFiscalWarehouse,
  updateFiscalWarehouse,
  terminal,
  createTerminal,
  updateTerminal,
  containers,
  createContainers,
  updateContainers,
  getFamilies,
  getLines,
  merchandise,
  createMerchandise,
  updateMerchandise,
  transports,
  createTransports,
  updateTransports,
  typeDocuments,
  createTypeDocument,
  updateTypeDocument,
  region,
  createRegion,
  updateRegion,
  getRegion,
  port,
  createPort,
  updatePort,
  getPorts,
  warehouse,
  createWarehouse,
  updateWarehouse,
  destination,
  createDestination,
  updateDestination,
  driver,
  createDriver,
  updateDriver,
  operativeTransport,
  createOperativeTransport,
  updateOperativeTransport,
  subregion,
  createSubregion,
  updateSubregion,
  getSubregion,
  operativeArea,
  createOperativeArea,
  updateOperativeArea,
  getOperativeArea,
  getOrganizationTypes,
  organization,
  createOrganization,
  updateOrganization,
});

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    return {...state, persistedState: action.payload};
  }
  if (action.type === LOGOUT) {
    state = undefined;
    // Clear All
  } else {
    state = { ...state }
  }
  if (state.login) {
    if (state.login?.expires) {
      if (moment(state.login.expires).isBefore(moment())) {
        state = undefined;
      }
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
