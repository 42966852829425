import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_REGION_ERROR,
  GET_ALL_REGION_START,
  GET_ALL_REGION_SUCCESS,
} from '../types';
import apiCall from '../../api/apiGet';

export function* regions({payload}) {
  try {
    console.log("LLEGA AL SAGAS LIST REGION");
    const url = '/Region/GetList';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_REGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_ALL_REGION_ERROR, error});
  }
}

export default function* getRegion() {
  yield takeLatest(GET_ALL_REGION_START, regions);
}
