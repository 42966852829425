import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_MERCHANDISE_ERROR,
  CREATE_MERCHANDISE_START,
  CREATE_MERCHANDISE_SUCCESS,
} from '../types';
import apiCall from '../../api';

export function* create({payload}) {
  try {
    const url = '/Mercaderia/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.name,
      productlineid: payload.productlineid,
      productfamilyid: payload.productfamilyid,
      isactive : true
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_MERCHANDISE_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: CREATE_MERCHANDISE_ERROR, error});
  }
}

export default function* createMerchandise() {
  yield takeLatest(CREATE_MERCHANDISE_START, create);
}
