import {takeLatest, put, call} from 'redux-saga/effects';
import {
  DELETE_FAMILIES_SUCCESS,
  DELETE_FAMILIES_ERROR,
  DELETE_FAMILIES_START
} from '../types';
import apiCall from '../../api';

export function* remove({payload}) {
  try {
    const url = '/ProductFamily/delete';
    const method = 'DELETE';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {id: payload.id}
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: DELETE_FAMILIES_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: DELETE_FAMILIES_ERROR, error});
  }
}

export default function* deleteFamilies() {
  yield takeLatest(DELETE_FAMILIES_START, remove);
}
