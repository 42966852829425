import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_TERMINAL_ERROR,
  GET_TERMINAL_START,
  GET_TERMINAL_SUCCESS
} from '../types';
import apiCall from '../../api/apiGet';

export function* getTerminal({payload}) {
  try {
    const url = `/PortTerminal/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_TERMINAL_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_TERMINAL_ERROR, error});
  }
}

export default function* terminal() {
  yield takeLatest(GET_TERMINAL_START, getTerminal);
}
