import {
  DELETE_FAMILIES_ERROR,
  DELETE_FAMILIES_START,
  DELETE_FAMILIES_SUCCESS,
} from '../types';

export default function (state, action) {
  switch (action.type) {
    case DELETE_FAMILIES_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELETE_FAMILIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case DELETE_FAMILIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
