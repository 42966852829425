import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_DRIVER_ERROR,
  CREATE_DRIVER_START,
  CREATE_DRIVER_SUCCESS,
} from '../../types';
import apiCall from '../../../api';

export function* create({payload}) {
  try {
    const url = '/Driver/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.name,
      phone: payload.phone,
      documents: payload.documents,
      isactive : true
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_DRIVER_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: CREATE_DRIVER_ERROR, error});
  }
}

export default function* createDriver() {
  yield takeLatest(CREATE_DRIVER_START, create);
}
