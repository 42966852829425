import {takeLatest, put, call} from 'redux-saga/effects';
import {
  UPDATE_WAREHOUSE_ERROR,
  UPDATE_WAREHOUSE_START,
  UPDATE_WAREHOUSE_SUCCESS
} from '../../types';
import apiCall from '../../../api';

export function* update({payload}) {
  try {
    const url = '/Warehouse/update';
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      id: payload.id,
      name: payload.name,
      isactive: payload.isActive
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: UPDATE_WAREHOUSE_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: UPDATE_WAREHOUSE_ERROR, error});
  }
}

export default function* updateWarehouse() {
  yield takeLatest(UPDATE_WAREHOUSE_START, update);
}
