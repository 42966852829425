import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector, Provider } from 'react-redux';
import i18n from 'i18next';

import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';

import './config-i18n';
import { setLanguage } from './redux/actions/language';

const App = () => {
  const { isLogged } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const routing = useRoutes(routes(isLogged));
  const languaje = useSelector((state => state.language))

  if (!languaje.language) {
    dispatch(setLanguage('es'))
  }
  i18n.changeLanguage(!languaje.language ? 'es' : languaje.language);

  return (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
            {routing}
        </ThemeProvider>
  );
};

export default App;
