import {
  CREATE_FISCAL_WAREHOUSE_START,
  CREATE_FISCAL_WAREHOUSE_SUCCESS,
  CREATE_FISCAL_WAREHOUSE_ERROR
} from '../types';

export default function (state, action) {
  switch (action.type) {
    case CREATE_FISCAL_WAREHOUSE_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_FISCAL_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_FISCAL_WAREHOUSE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return {...state};
  }
}
