import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_DESTINATION_START,
  GET_DESTINATION_SUCCESS,
  GET_DESTINATION_ERROR,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* getDestination({payload}) {
  try {
    const url = `/Destination/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_DESTINATION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_DESTINATION_ERROR, error});
  }
}

export default function* destination() {
  yield takeLatest(GET_DESTINATION_START, getDestination);
}
