import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_OPERATIVEAREA_START,
  GET_OPERATIVEAREA_SUCCESS,
  GET_OPERATIVEAREA_ERROR,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* getOperativeArea({payload}) {
  try {
    const url = `/OperationalArea/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_OPERATIVEAREA_SUCCESS, results: results.data});
  } catch (error) {

    yield put({type: GET_OPERATIVEAREA_ERROR, error});
  }
}

export default function* operativeArea() {
  yield takeLatest(GET_OPERATIVEAREA_START, getOperativeArea);
}
