import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_ORGANIZATIONTYPES_ERROR,
  GET_ALL_ORGANIZATIONTYPES_START,
  GET_ALL_ORGANIZATIONTYPES_SUCCESS,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* organizationTypes({payload}) {
  try {
    const url = '/Organization/GetOrganizationTypes';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_ORGANIZATIONTYPES_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_ALL_ORGANIZATIONTYPES_ERROR, error});
  }
}

export default function* getOrganizationTypes() {
  yield takeLatest(GET_ALL_ORGANIZATIONTYPES_START, organizationTypes);
}
