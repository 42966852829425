import {
  GET_ALL_FAMILIES_ERROR,
  GET_ALL_FAMILIES_START, GET_ALL_FAMILIES_SUCCESS
} from '../types';

export default function (state, action) {
  switch (action.type) {
    case GET_ALL_FAMILIES_START:
      return {
        ...state,
        quantity: 0,
        families: [],
        loading: true,
        error: null
      };
    case GET_ALL_FAMILIES_SUCCESS:
      return {
        ...state,
        quantity: action.results.quantity,
        families: action.results.productfamilies,
        loading: false,
        error: null
      };
    case GET_ALL_FAMILIES_ERROR:
      return {
        ...state,
        quantity: 0,
        families: [],
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
