import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_OPERATIVEAREA_ERROR,
  CREATE_OPERATIVEAREA_START,
  CREATE_OPERATIVEAREA_SUCCESS,
} from '../../types';
import apiCall from '../../../api';

export function* create({payload}) {
  try {
    console.log(payload);
    const url = '/OperationalArea/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.Name,
      isactive : true
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_OPERATIVEAREA_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: CREATE_OPERATIVEAREA_ERROR, error});
  }
}

export default function* createOperativeArea() {
  yield takeLatest(CREATE_OPERATIVEAREA_START, create);
}
