import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_CONTAINER_SUCCESS,
  GET_CONTAINER_ERROR,
  GET_CONTAINER_START,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getContainers({payload}) {
  try {
    const url = `/ContainerType/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_CONTAINER_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_CONTAINER_ERROR, error});
  }
}

export default function* containers() {
  yield takeLatest(GET_CONTAINER_START, getContainers);
}
