import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_FAMILIES_ERROR,
  GET_ALL_FAMILIES_START,
  GET_ALL_FAMILIES_SUCCESS,
} from '../types';
import apiCall from '../../api/apiGet';

export function* families({payload}) {
  try {
    const url = '/ProductFamily/GetList';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_ALL_FAMILIES_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_ALL_FAMILIES_ERROR, error});
  }
}

export default function* getFamilies() {
  yield takeLatest(GET_ALL_FAMILIES_START, families);
}
