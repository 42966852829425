import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_OPERATIVEAREA_ERROR,
  GET_ALL_OPERATIVEAREA_START,
  GET_ALL_OPERATIVEAREA_SUCCESS,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* operativeAreas({payload}) {
  try {
    const url = '/OperationalArea/GetList';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_OPERATIVEAREA_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_ALL_OPERATIVEAREA_ERROR, error});
  }
}

export default function* getOperativeArea() {
  yield takeLatest(GET_ALL_OPERATIVEAREA_START, operativeAreas);
}
