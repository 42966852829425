import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_DRIVER_START,
  GET_DRIVER_SUCCESS,
  GET_DRIVER_ERROR,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* getDriver({payload}) {
  try {
    const url = `/Driver/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_DRIVER_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_DRIVER_ERROR, error});
  }
}

export default function* driver() {
  yield takeLatest(GET_DRIVER_START, getDriver);
}
