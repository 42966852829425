import {takeLatest, put, call} from 'redux-saga/effects';
import {
  UPDATE_OPERATIVEAREA_ERROR,
  UPDATE_OPERATIVEAREA_START,
  UPDATE_OPERATIVEAREA_SUCCESS
} from '../../types';
import apiCall from '../../../api';

export function* update({payload}) {
  try {
    console.log("PAYLOAD en update");
    console.log(payload);
    const url = '/OperationalArea/Update';
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      id: payload.id,
      name: payload.name,
      isactive: payload.isActive
    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: UPDATE_OPERATIVEAREA_SUCCESS, results: results.data});
  } catch (error) {

    yield put({type: UPDATE_OPERATIVEAREA_ERROR, error});
  }
}

export default function* updateOperativeArea() {
  yield takeLatest(UPDATE_OPERATIVEAREA_START, update);
}
