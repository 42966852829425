export const LOGOUT = 'LOGOUT';
export const CLOSE_USER_DATA = 'CLOSE_USER_DATA';

export const SET_LANGUAGE_START = 'SET_LANGUAGE_START';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR';

export const POST_LOGIN_START = 'POST_LOGIN_START';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';

export const GET_ORGANIZATIONS_START = 'GET_ORGANIZATIONS_START';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR';

export const GET_LINES_START = 'GET_LINES_START';
export const GET_LINES_SUCCESS = 'GET_LINES_SUCCESS';
export const GET_LINES_ERROR = 'GET_LINES_ERROR';

export const CREATE_LINE_START = 'CREATE_LINE_START';
export const CREATE_LINE_SUCCESS = 'CREATE_LINE_SUCCESS';
export const CREATE_LINE_ERROR = 'CREATE_LINE_ERROR';

export const UPDATE_LINE_START = 'UPDATE_LINE_START';
export const UPDATE_LINE_SUCCESS = 'UPDATE_LINE_SUCCESS';
export const UPDATE_LINE_ERROR = 'UPDATE_LINE_ERROR';

export const DELETE_LINE_START = 'DELETE_LINE_START';
export const DELETE_LINE_SUCCESS = 'DELETE_LINE_SUCCESS';
export const DELETE_LINE_ERROR = 'DELETE_LINE_ERROR';

export const GET_FAMILIES_START = 'GET_FAMILIES_START';
export const GET_FAMILIES_SUCCESS = 'GET_FAMILIES_SUCCESS';
export const GET_FAMILIES_ERROR = 'GET_FAMILIES_ERROR';

export const CREATE_FAMILIES_START = 'CREATE_FAMILIES_START';
export const CREATE_FAMILIES_SUCCESS = 'CREATE_FAMILIES_SUCCESS';
export const CREATE_FAMILIES_ERROR = 'CREATE_FAMILIES_ERROR';

export const UPDATE_FAMILIES_START = 'UPDATE_FAMILIES_START';
export const UPDATE_FAMILIES_SUCCESS = 'UPDATE_FAMILIES_SUCCESS';
export const UPDATE_FAMILIES_ERROR = 'UPDATE_FAMILIES_ERROR';

export const DELETE_FAMILIES_START = 'DELETE_FAMILIES_START';
export const DELETE_FAMILIES_SUCCESS = 'DELETE_FAMILIES_SUCCESS';
export const DELETE_FAMILIES_ERROR = 'DELETE_FAMILIES_ERROR';

export const GET_CUSTOMS_START = 'GET_CUSTOMS_START';
export const GET_CUSTOMS_SUCCESS = 'GET_CUSTOMS_SUCCESS';
export const GET_CUSTOMS_ERROR = 'GET_CUSTOMS_ERROR';

export const CREATE_CUSTOMS_START = 'CREATE_CUSTOMS_START';
export const CREATE_CUSTOMS_SUCCESS = 'CREATE_CUSTOMS_SUCCESS';
export const CREATE_CUSTOMS_ERROR = 'CREATE_CUSTOMS_ERROR';

export const UPDATE_CUSTOMS_START = 'UPDATE_CUSTOMS_START';
export const UPDATE_CUSTOMS_SUCCESS = 'UPDATE_CUSTOMS_SUCCESS';
export const UPDATE_CUSTOMS_ERROR = 'UPDATE_CUSTOMS_ERROR';

export const DELETE_CUSTOMS_START = 'DELETE_CUSTOMS_START';
export const DELETE_CUSTOMS_SUCCESS = 'DELETE_CUSTOMS_SUCCESS';
export const DELETE_CUSTOMS_ERROR = 'DELETE_CUSTOMS_ERROR';

export const GET_CHANNELS_START = 'GET_CHANNELS_START';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_ERROR = 'GET_CHANNELS_ERROR';

export const CREATE_CHANNELS_START = 'CREATE_CHANNELS_START';
export const CREATE_CHANNELS_SUCCESS = 'CREATE_CHANNELS_SUCCESS';
export const CREATE_CHANNELS_ERROR = 'CREATE_CHANNELS_ERROR';

export const UPDATE_CHANNELS_START = 'UPDATE_CHANNELS_START';
export const UPDATE_CHANNELS_SUCCESS = 'UPDATE_CHANNELS_SUCCESS';
export const UPDATE_CHANNELS_ERROR = 'UPDATE_CHANNELS_ERROR';

export const DELETE_CHANNELS_START = 'DELETE_CHANNELS_START';
export const DELETE_CHANNELS_SUCCESS = 'DELETE_CHANNELS_SUCCESS';
export const DELETE_CHANNELS_ERROR = 'DELETE_CHANNELS_ERROR';

export const GET_FISCAL_WAREHOUSE_START = 'GET_FISCAL_WAREHOUSE_START';
export const GET_FISCAL_WAREHOUSE_SUCCESS = 'GET_FISCAL_WAREHOUSE_SUCCESS';
export const GET_FISCAL_WAREHOUSE_ERROR = 'GET_FISCAL_WAREHOUSE_ERROR';

export const CREATE_FISCAL_WAREHOUSE_START = 'CREATE_FISCAL_WAREHOUSE_START';
export const CREATE_FISCAL_WAREHOUSE_SUCCESS = 'CREATE_FISCAL_WAREHOUSE_SUCCESS';
export const CREATE_FISCAL_WAREHOUSE_ERROR = 'CREATE_FISCAL_WAREHOUSE_ERROR';

export const UPDATE_FISCAL_WAREHOUSE_START = 'UPDATE_FISCAL_WAREHOUSE_START';
export const UPDATE_FISCAL_WAREHOUSE_SUCCESS = 'UPDATE_FISCAL_WAREHOUSE_SUCCESS';
export const UPDATE_FISCAL_WAREHOUSE_ERROR = 'UPDATE_FISCAL_WAREHOUSE_ERROR';

export const DELETE_FISCAL_WAREHOUSE_START = 'DELETE_FISCAL_WAREHOUSE_START';
export const DELETE_FISCAL_WAREHOUSE_SUCCESS = 'DELETE_FISCAL_WAREHOUSE_SUCCESS';
export const DELETE_FISCAL_WAREHOUSE_ERROR = 'DELETE_FISCAL_WAREHOUSE_ERROR';

export const GET_TERMINAL_START = 'GET_TERMINAL_START';
export const GET_TERMINAL_SUCCESS = 'GET_TERMINAL_SUCCESS';
export const GET_TERMINAL_ERROR = 'GET_TERMINAL_ERROR';

export const CREATE_TERMINAL_START = 'CREATE_TERMINAL_START';
export const CREATE_TERMINAL_SUCCESS = 'CREATE_TERMINAL_SUCCESS';
export const CREATE_TERMINAL_ERROR = 'CREATE_TERMINAL_ERROR';

export const UPDATE_TERMINAL_START = 'UPDATE_TERMINAL_START';
export const UPDATE_TERMINAL_SUCCESS = 'UPDATE_TERMINAL_SUCCESS';
export const UPDATE_TERMINAL_ERROR = 'UPDATE_TERMINAL_ERROR';

export const DELETE_TERMINAL_START = 'DELETE_TERMINAL_START';
export const DELETE_TERMINAL_SUCCESS = 'DELETE_TERMINAL_SUCCESS';
export const DELETE_TERMINAL_ERROR = 'DELETE_TERMINAL_ERROR';

export const GET_CONTAINER_START = 'GET_CONTAINER_START';
export const GET_CONTAINER_SUCCESS = 'GET_CONTAINER_SUCCESS';
export const GET_CONTAINER_ERROR = 'GET_CONTAINER_ERROR';

export const CREATE_CONTAINER_START = 'CREATE_CONTAINER_START';
export const CREATE_CONTAINER_SUCCESS = 'CREATE_CONTAINER_SUCCESS';
export const CREATE_CONTAINER_ERROR = 'CREATE_CONTAINER_ERROR';

export const UPDATE_CONTAINER_START = 'UPDATE_CONTAINER_START';
export const UPDATE_CONTAINER_SUCCESS = 'UPDATE_CONTAINER_SUCCESS';
export const UPDATE_CONTAINER_ERROR = 'UPDATE_CONTAINER_ERROR';

export const GET_ALL_LINES_START = 'GET_ALL_LINES_START';
export const GET_ALL_LINES_SUCCESS = 'GET_ALL_LINES_SUCCESS';
export const GET_ALL_LINES_ERROR = 'GET_ALL_LINES_ERROR';

export const GET_ALL_FAMILIES_START = 'GET_ALL_FAMILIES_START';
export const GET_ALL_FAMILIES_SUCCESS = 'GET_ALL_FAMILIES_SUCCESS';
export const GET_ALL_FAMILIES_ERROR = 'GET_ALL_FAMILIES_ERROR';

export const GET_MERCHANDISE_START = 'GET_MERCHANDISE_START';
export const GET_MERCHANDISE_SUCCESS = 'GET_MERCHANDISE_SUCCESS';
export const GET_MERCHANDISE_ERROR = 'GET_MERCHANDISE_ERROR';

export const CREATE_MERCHANDISE_START = 'CREATE_CONTAINER_START';
export const CREATE_MERCHANDISE_SUCCESS = 'CREATE_CONTAINER_SUCCESS';
export const CREATE_MERCHANDISE_ERROR = 'CREATE_CONTAINER_ERROR';

export const UPDATE_MERCHANDISE_START = 'UPDATE_MERCHANDISE_START';
export const UPDATE_MERCHANDISE_SUCCESS = 'UPDATE_MERCHANDISE_SUCCESS';
export const UPDATE_MERCHANDISE_ERROR = 'UPDATE_MERCHANDISE_ERROR';

export const GET_TRANSPORT_START = 'GET_TRANSPORT_START';
export const GET_TRANSPORT_SUCCESS = 'GET_TRANSPORT_SUCCESS';
export const GET_TRANSPORT_ERROR = 'GET_TRANSPORT_ERROR';

export const CREATE_TRANSPORT_START = 'CREATE_TRANSPORT_START';
export const CREATE_TRANSPORT_SUCCESS = 'CREATE_TRANSPORT_SUCCESS';
export const CREATE_TRANSPORT_ERROR = 'CREATE_TRANSPORT_ERROR';

export const UPDATE_TRANSPORT_START = 'UPDATE_TRANSPORT_START';
export const UPDATE_TRANSPORT_SUCCESS = 'UPDATE_TRANSPORT_SUCCESS';
export const UPDATE_TRANSPORT_ERROR = 'UPDATE_TRANSPORT_ERROR';

export const GET_TYPEDOCUMENT_START = 'GET_TYPEDOCUMENT_START';
export const GET_TYPEDOCUMENT_SUCCESS = 'GET_TYPEDOCUMENT_SUCCESS';
export const GET_TYPEDOCUMENT_ERROR = 'GET_TYPEDOCUMENT_ERROR';

export const CREATE_TYPEDOCUMENT_START = 'CREATE_TYPEDOCUMENT_START';
export const CREATE_TYPEDOCUMENT_SUCCESS = 'CREATE_TYPEDOCUMENT_SUCCESS';
export const CREATE_TYPEDOCUMENT_ERROR = 'CREATE_TYPEDOCUMENT_ERROR';

export const UPDATE_TYPEDOCUMENT_START = 'UPDATE_TYPEDOCUMENT_START';
export const UPDATE_TYPEDOCUMENT_SUCCESS = 'UPDATE_TYPEDOCUMENT_SUCCESS';
export const UPDATE_TYPEDOCUMENT_ERROR = 'UPDATE_TYPEDOCUMENT_ERROR';

export const GET_REGION_START = 'GET_REGION_START';
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';
export const GET_REGION_ERROR = 'GET_REGION_ERROR';

export const CREATE_REGION_START = 'CREATE_REGION_START';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_ERROR = 'CREATE_REGION_ERROR';

export const UPDATE_REGION_START = 'UPDATE_REGION_START';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_ERROR = 'UPDATE_REGION_ERROR';

export const GET_ALL_REGION_START = 'GET_ALL_REGION_START';
export const GET_ALL_REGION_SUCCESS = 'GET_ALL_REGION_SUCCESS';
export const GET_ALL_REGION_ERROR = 'GET_ALL_REGION_ERROR';

export const GET_PORT_START = 'GET_PORT_START';
export const GET_PORT_SUCCESS = 'GET_PORT_SUCCESS';
export const GET_PORT_ERROR = 'GET_PORT_ERROR';

export const CREATE_PORT_START = 'CREATE_PORT_START';
export const CREATE_PORT_SUCCESS = 'CREATE_PORT_SUCCESS';
export const CREATE_PORT_ERROR = 'CREATE_PORT_ERROR';

export const UPDATE_PORT_START = 'UPDATE_PORT_START';
export const UPDATE_PORT_SUCCESS = 'UPDATE_PORT_SUCCESS';
export const UPDATE_PORT_ERROR = 'UPDATE_PORT_ERROR';

export const GET_ALL_PORT_START = 'GET_ALL_PORT_START';
export const GET_ALL_PORT_SUCCESS = 'GET_ALL_PORT_SUCCESS';
export const GET_ALL_PORT_ERROR = 'GET_ALL_PORT_ERROR';

export const GET_WAREHOUSE_START = 'GET_WAREHOUSE_START';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
export const GET_WAREHOUSE_ERROR = 'GET_WAREHOUSE_ERROR';

export const CREATE_WAREHOUSE_START = 'CREATE_WAREHOUSE_START';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_ERROR = 'CREATE_WAREHOUSE_ERROR';

export const UPDATE_WAREHOUSE_START = 'UPDATE_WAREHOUSE_START';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_ERROR = 'UPDATE_WAREHOUSE_ERROR';

export const GET_DESTINATION_START = 'GET_DESTINATION_START';
export const GET_DESTINATION_SUCCESS = 'GET_DESTINATION_SUCCESS';
export const GET_DESTINATION_ERROR = 'GET_DESTINATION_ERROR';

export const CREATE_DESTINATION_START = 'CREATE_DESTINATION_START';
export const CREATE_DESTINATION_SUCCESS = 'CREATE_DESTINATION_SUCCESS';
export const CREATE_DESTINATION_ERROR = 'CREATE_DESTINATION_ERROR';

export const UPDATE_DESTINATION_START = 'UPDATE_DESTINATION_START';
export const UPDATE_DESTINATION_SUCCESS = 'UPDATE_DESTINATION_SUCCESS';
export const UPDATE_DESTINATION_ERROR = 'UPDATE_DESTINATION_ERROR';

export const GET_DRIVER_START = 'GET_DRIVER_START';
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS';
export const GET_DRIVER_ERROR = 'GET_DRIVER_ERROR';

export const CREATE_DRIVER_START = 'CREATE_DRIVER_START';
export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS';
export const CREATE_DRIVER_ERROR = 'CREATE_DRIVER_ERROR';

export const UPDATE_DRIVER_START = 'UPDATE_DRIVER_START';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_ERROR = 'UPDATE_DRIVER_ERROR';

export const GET_OPERATIVE_TRANSPORT_START = 'GET_OPERATIVE_TRANSPORT_START';
export const GET_OPERATIVE_TRANSPORT_SUCCESS = 'GET_OPERATIVE_TRANSPORT_SUCCESS';
export const GET_OPERATIVE_TRANSPORT_ERROR = 'GET_OPERATIVE_TRANSPORT_ERROR';

export const CREATE_OPERATIVE_TRANSPORT_START = 'CREATE_OPERATIVE_TRANSPORT_START';
export const CREATE_OPERATIVE_TRANSPORT_SUCCESS = 'CREATE_OPERATIVE_TRANSPORT_SUCCESS';
export const CREATE_OPERATIVE_TRANSPORT_ERROR = 'CREATE_OPERATIVE_TRANSPORT_ERROR';

export const UPDATE_OPERATIVE_TRANSPORT_START = 'UPDATE_OPERATIVE_TRANSPORT_START';
export const UPDATE_OPERATIVE_TRANSPORT_SUCCESS = 'UPDATE_OPERATIVE_TRANSPORT_SUCCESS';
export const UPDATE_OPERATIVE_TRANSPORT_ERROR = 'UPDATE_OPERATIVE_TRANSPORT_ERROR';

export const GET_SUBREGION_START = 'GET_SUBREGION_START';
export const GET_SUBREGION_SUCCESS = 'GET_SUBREGION_SUCCESS';
export const GET_SUBREGION_ERROR = 'GET_SUBREGION_ERROR';

export const CREATE_SUBREGION_START = 'CREATE_SUBREGION_START';
export const CREATE_SUBREGION_SUCCESS = 'CREATE_SUBREGION_SUCCESS';
export const CREATE_SUBREGION_ERROR = 'CREATE_SUBREGION_ERROR';

export const UPDATE_SUBREGION_START = 'UPDATE_SUBREGION_START';
export const UPDATE_SUBREGION_SUCCESS = 'UPDATE_SUBREGION_SUCCESS';
export const UPDATE_SUBREGION_ERROR = 'UPDATE_SUBREGION_ERROR';

export const GET_ALL_SUBREGION_START = 'GET_ALL_SUBREGION_START';
export const GET_ALL_SUBREGION_SUCCESS = 'GET_ALL_SUBREGION_SUCCESS';
export const GET_ALL_SUBREGION_ERROR = 'GET_ALL_SUBREGION_ERROR';

export const GET_OPERATIVEAREA_START = 'GET_OPERATIVEAREA_START';
export const GET_OPERATIVEAREA_SUCCESS = 'GET_OPERATIVEAREA_SUCCESS';
export const GET_OPERATIVEAREA_ERROR = 'GET_OPERATIVEAREA_ERROR';

export const CREATE_OPERATIVEAREA_START = 'CREATE_OPERATIVEAREA_START';
export const CREATE_OPERATIVEAREA_SUCCESS = 'CREATE_OPERATIVEAREA_SUCCESS';
export const CREATE_OPERATIVEAREA_ERROR = 'CREATE_OPERATIVEAREA_ERROR';

export const UPDATE_OPERATIVEAREA_START = 'UPDATE_OPERATIVEAREA_START';
export const UPDATE_OPERATIVEAREA_SUCCESS = 'UPDATE_OPERATIVEAREA_SUCCESS';
export const UPDATE_OPERATIVEAREA_ERROR = 'UPDATE_OPERATIVEAREA_ERROR';

export const GET_ALL_OPERATIVEAREA_START = 'GET_ALL_OPERATIVEAREA_START';
export const GET_ALL_OPERATIVEAREA_SUCCESS = 'GET_ALL_OPERATIVEAREA_SUCCESS';
export const GET_ALL_OPERATIVEAREA_ERROR = 'GET_ALL_OPERATIVEAREA_ERROR';

export const GET_ORGANIZATION_START = 'GET_ORGANIZATION_START';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

export const GET_ALL_ORGANIZATIONTYPES_START = 'GET_ALL_ORGANIZATIONTYPES_START';
export const GET_ALL_ORGANIZATIONTYPES_SUCCESS = 'GET_ALL_ORGANIZATIONTYPES_SUCCESS';
export const GET_ALL_ORGANIZATIONTYPES_ERROR = 'GET_ALL_ORGANIZATIONTYPES_ERROR';
