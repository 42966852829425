import React, { useEffect, useState } from 'react';
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Collapse,
  makeStyles
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ExpandLess,
  ExpandMore,
  LinearScale,
  AccountTree,
  Dashboard,
  ListAlt,
  BuildCircle,
  Build
} from '@material-ui/icons';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    height: '100%',
    maxHeight: '100%',
    backgroundColor: '#262d34',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  text: {
    color: '#edeef1',
    paddingLeft: 10,
  },
  textSub: {
    color: '#edeef1',
    paddingLeft: 10,
  }
}));

const NavItem = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let location = useLocation();
  const [loc, setLoc] = useState();
  const [open, setOpen] = useState(false);
  const [logistica, setLogistica] = useState(false);
  const [operativa, setOperativa] = useState(false);
  const [config, setConfig] = useState(false);

  useEffect(() => {
    console.log(location.pathname);
    setLoc(location.pathname)
  }, [location]);


  const handleClick = () => {
    setOpen(!open);
    setLogistica(false);
    setOperativa(false);
    setConfig(false);
  };

  const handleClickLog = () => {
    setLogistica(!logistica);
    setOpen(false);
    setOperativa(false);
    setConfig(false);
  };

  const handleClickOperative = () => {
    setOperativa(!operativa);
    setOpen(false);
    setLogistica(false);
    setConfig(false);
  };

  const handleClickConfiguration = () => {
    setConfig(!config);
    setOpen(false);
    setLogistica(false);
    setOperativa(false);
  };

  const products = [
    {
      url: '/app/products/lines',
      name: 'Lineas',
    },
    {
      url: '/app/products/families',
      name: 'Familias',
    },
    {
      url: '/app/products/merchandise',
      name: 'Mercaderias',
    },
  ]
  const logistics = [
    {
      url: '/app/logistics/customs',
      name: 'Aduanas',
    },
    {
      url: '/app/logistics/channels',
      name: 'Canales',
    },
    {
      url: '/app/logistics/deposits',
      name: 'Depósitos Fiscales',
    },
    {
      url: '/app/logistics/ports',
      name: 'Puertos',
    },
    {
      url: '/app/logistics/terminal',
      name: 'Terminales',
    },
    {
      url: '/app/logistics/container',
      name: 'Tipos de Contenedor',
    },
    {
      url: '/app/logistics/transports',
      name: 'Tipos de Camión',
    },
    {
      url: '/app/logistics/DocumentType',
      name: 'Tipos de Documento',
    },
  ]
  const operative = [
    {
      url: '/app/operative/driver',
      name: 'Choferes',
    },
    {
      url: '#',
      name: 'Contenedores',
    },
    {
      url: '/app/operative/destination',
      name: 'Destinos',
    },
    {
      url: '/app/operative/warehouse',
      name: 'Depósitos',
    },
    {
      url: '/app/operative/transport',
      name: 'Transportes',
    },
  ]
  const configuration = [
    {
      url: '#',
      name: 'Contactos',
    },
    {
      url: 'configuration/others',
      name: 'Region',
    },
    {
      url: 'configuration/subregion',
      name: 'SubRegion',
    },
    {
      url: 'configuration/operativearea',
      name: 'Areas Operativas',
    },
  ]

  const others = [
    {
      url: '#',
      name: ' Country/Region',
    },
    {
      url: '#',
      name: 'Province/Subregion',
    }

  ]
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <RouterLink to="/app/dashboard">
        <ListItem button>
          <ListItemIcon>
            <Dashboard style={{ color: '#edeef1' }} />
          </ListItemIcon>
          <ListItemText primary={`${t('menu:text_dashboard')}`} className={classes.text} />
        </ListItem>
      </RouterLink>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ListAlt style={{ color: '#edeef1 ' }} />
        </ListItemIcon>
        <ListItemText primary={`${t('menu:text_product')}`} className={classes.text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {products.map((item, i) => (
            <RouterLink to={item.url}>
              <ListItem button className={classes.nested} selected={item.url === loc ? true : false}>
                <ListItemText primary={item.name} className={classes.textSub} />
              </ListItem>
            </RouterLink>
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleClickLog}>
        <ListItemIcon>
          <AccountTree style={{ color: '#edeef1' }} />
        </ListItemIcon>
        <ListItemText primary={'Logistica'} className={classes.text} />
        {logistica ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={logistica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {logistics.map((item, i) => (
            <RouterLink to={item.url}>
              <ListItem button className={classes.nested} selected={item.url === loc ? true : false}>
                <ListItemText primary={item.name} className={classes.textSub} />
              </ListItem>
            </RouterLink>

          ))}
        </List>
      </Collapse>
      <ListItem button onClick={handleClickOperative}>
        <ListItemIcon>
          <LinearScale style={{ color: '#edeef1' }} />
        </ListItemIcon>
        <ListItemText primary={'Operativa'} className={classes.text} />
        {operativa ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={operativa} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {operative.map((item, i) => (
            <RouterLink to={item.url}>
              <ListItem button className={classes.nested} selected={item.url === loc ? true : false}>
                <ListItemText primary={item.name} className={classes.textSub} />
              </ListItem>
            </RouterLink>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={handleClickConfiguration}>
        <ListItemIcon>
          <Build style={{ color: '#edeef1' }} />
        </ListItemIcon>
        <ListItemText primary={'Configuracion'} className={classes.text} />
        {config ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={config} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {configuration.map((item, i) => (
            <RouterLink to={item.url}>
              <ListItem button className={classes.nested} selected={item.url === loc ? true : false}>
                <ListItemText primary={item.name} className={classes.textSub} />
              </ListItem>
            </RouterLink>


          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default NavItem;
