import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { loginUser } from '../redux/actions/login';
import { getOrganizations } from '../redux/actions/getOrganizations';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    width: '50%'
  },
}));

const style = {
  paperContainer: {
    width: '100%',
    heigth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5%',
  },
  paper: {
    marginTop: 9,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 5,
  },
  form: {
    width: "90%",
    marginTop: 8,
  },
  containerForm: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

const Login = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [organization, setOrganization] = useState({OrganizationId: null, Name: null});
  const [new_org, setNew_Org] = useState([]);
  const login = useSelector((state) => state.login);
  const org = useSelector((state) => state.getOrganizations)
  const [state, setState] = useState({IDCompany: organization.id, username : '', password : '', idioma : 'ES'});

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(loginUser(state));
  }
  const handleChange = (e) => {
    const {name, value} = e.target;
    setState((prevState) => ({...prevState, [name]: value}));
  };

  useEffect(() => {
    setState((prevState) => ({...prevState, ['IDCompany']: organization.id}));
  }, [organization])

  useEffect(() => {
    if (login.isLogged) {
      localStorage.setItem('user', JSON.stringify(login));
      navigate('/app/dashboard', { replace: true });
    }
  }, [login]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getOrg = () => {
    if (state.username.length > 1) {
      dispatch(getOrganizations({username: state.username}))
    }
  }

  useEffect(() => {
    getOrg();
  }, [state.username])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderWidth: '2px',
      color: state.isSelected ? theme.palette.background.yellow : theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      padding: 20,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    control: (styles) => ({
      ...styles,
      width: '100%',
      borderWidth: '0.5px',
      paddingTop: 10,
      paddingBottom: 10,
      boxShadow: theme.palette.text.secondary,
      '&:hover': {
        border: '0.5px theme.palette.text.secondary',
      },
      opacity: '300ms',
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      marginTop: 15,
      marginBottom: 15,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
    <div style={style.paperContainer}>
      <Helmet>
        <title>{t('common:text_signin')} | Akeron - {t('common:description')}</title>
      </Helmet>
        <Container maxWidth={"xs"} style={style.containerForm}>
          <div style={style.paper}>
            <form>
              <TextField
                fullWidth
                label={t('auth:text_input_username')}
                margin="normal"
                name="username"
                type="text"
                variant="outlined"
                value={state.username}
                onChange={e => handleChange(e)}
              />
              <TextField
                fullWidth
                label={t('auth:text_input_password')}
                margin="normal"
                name="password"
                type="password"
                variant="outlined"
                value={state.password}
                onChange={e => handleChange(e)}
              />
              <Select
                name="name"
                options={org.organizations ? org.organizations : []}
                value={organization?.name}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={(newValue) => {
                  setOrganization(newValue);
                }}
                styles={customStyles}
                placeholder={organization.name ? organization.name : 'Seleccione una opción'}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {t('auth:title')}
                </Button>
              </Box>
            </form>
          </div>
        </Container>
    </div>
  );
};

export default Login;
