import { POST_LOGIN_START, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR, LOGOUT } from '../types';

export default function (state, action) {
  switch (action.type) {
    case POST_LOGIN_START:
      return { ...state };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        token: action.results.token,
        expires: action.results.expires,
        username: action.results.username,
        roles: action.results.roles,
        name: action.results.name,
        sucursal: action.results.sucursal,
        error: null,
      };
    case POST_LOGIN_ERROR:
      return {
        ...state,
        isLogged: false,
        error: action.error
      };
    case LOGOUT:
      state = {}
      return {
        isLogged: false
      }
    default:
      return { ...state };
  }
}
