import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_ALL_LINES_ERROR,
  GET_ALL_LINES_START,
  GET_ALL_LINES_SUCCESS,
} from '../types';
import apiCall from '../../api/apiGet';

export function* lines({payload}) {
  try {
    const url = '/ProductLine/GetList';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_LINES_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_ALL_LINES_ERROR, error});
  }
}

export default function* getLines() {
  yield takeLatest(GET_ALL_LINES_START, lines);
}
