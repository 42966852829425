import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_FISCAL_WAREHOUSE_SUCCESS,
  GET_FISCAL_WAREHOUSE_ERROR,
  GET_FISCAL_WAREHOUSE_START,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getWarehouse({payload}) {
  try {
    const url = `/FiscalWarehouse/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    console.log(url)
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_FISCAL_WAREHOUSE_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_FISCAL_WAREHOUSE_ERROR, error});
  }
}

export default function* fiscalwarehouse() {
  yield takeLatest(GET_FISCAL_WAREHOUSE_START, getWarehouse);
}
