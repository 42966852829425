import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  Container,
  Fade, FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography, useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Autorenew from '@material-ui/icons/Autorenew';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { warehouse } from '../../redux/actions/OperativeWarehouse/warehouse';
import { createWarehouse } from '../../redux/actions/OperativeWarehouse/createWarehouse';
import { updateWarehouse } from '../../redux/actions/OperativeWarehouse/updateWarehouse';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #36b34a',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const OperativeWarehouse = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [create, setCreate] = useState({ name: null });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [pagInitial, setPagInitial] = useState(0);
  const [pagEnd, setPagEnd] = useState(10);
  const [valueLine, setValueLine] = useState(null);
  const [active, setActive] = useState(true);
  const getWarehouse = useSelector(state => state.warehouse);
  const token = useSelector(state => state.login.token);
  const warehouseCreate = useSelector(state => state.createWarehouse);
  const warehouseUpdate = useSelector(state => state.updateWarehouse);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    setOpenDelete(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      setPagInitial(pagInitial + 10);
      setPagEnd(pagEnd + 10);
    } else {
      setPage(newPage);
      setPagInitial(pagInitial - 10);
      setPagEnd(pagEnd - 10);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setItemSelected((prevState) => ({...prevState, [name]: value}));
  };

  const handleChangeCreate = (e) => {
    const {name, value} = e.target;
    setCreate((prevState) => ({...prevState, [name]: value}));
  };

  const handleSubmitCreate = e => {
    e.preventDefault()
    if (create.name) {
      dispatch(createWarehouse({ token, name: create.name}));
      handleClose();
    }
  }

  const handleSubmitUpdate = e => {
    e.preventDefault()
    dispatch(updateWarehouse({
      token,
      id: itemSelected.id,
      name: itemSelected.name,
      isActive
    }));
    handleClose();
  }

  const getWarehousesAll = () => {
    dispatch(warehouse({token, value: valueLine ? valueLine : ' ', pagInitial, pagEnd, active: active ? 1 : 0}))
  }

  const handleValue = (e) => {
    const {name, value} = e.target;
    setValueLine(value);
  };


  useEffect(() => {
    getWarehousesAll();
  }, [active, valueLine, pagInitial, pagEnd])

  useEffect(() => {
    if (!warehouseCreate.loading) {
      setCreate(null);
      setItemSelected({ name: null, itemSelected: null });
      getWarehousesAll();
    }
  }, [warehouseCreate.loading]);

  useEffect(() => {
    if (!warehouseUpdate.loading) {
      setCreate(null);
      setItemSelected({ name: null, itemSelected: null });
      getWarehousesAll();
    }
  }, [warehouseUpdate.loading]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderWidth: '2px',
      color: state.isSelected ? theme.palette.background.yellow : theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      padding: 20,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    control: (styles) => ({
      ...styles,
      width: '100%',
      borderWidth: '0.5px',
      paddingTop: 10,
      paddingBottom: 10,
      boxShadow: theme.palette.text.secondary,
      '&:hover': {
        border: '0.5px theme.palette.text.secondary',
      },
      opacity: '300ms',
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      marginTop: 15,
      marginBottom: 15,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
    <>
      <Helmet>
        <title>Akeron - {t('common:description')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenCreate(true);
                handleOpenCreate();
              }}
            >
              Nuevo Deposito
            </Button>
            <IconButton color="inherit" onClick={() => getWarehousesAll()}>
              <Autorenew style={{ color: '#36b34a' }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }} display="flex" flexDirection="row" alignItems="center">
                  <Box p={1}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      name={'value'}
                      placeholder="Buscar Deposito"
                      variant="outlined"
                      value={valueLine}
                      onChange={e => handleValue(e)}
                    />
                  </Box>
                  <Box p={1}>
                    <FormControlLabel
                      value="Activos"
                      control={<Checkbox color="primary" checked={active} onChange={event => setActive(!active)} />}
                      label="Activos"
                      labelPlacement="Activos"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ pt: 2 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ padding: 1, borderWidth: 0 }}>
                  {!getWarehouse.loading ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Depósito
                          </TableCell>
                          <TableCell>
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {getWarehouse.quantity > 0 ?
                        <TableBody>
                          {getWarehouse.entries.slice(0, limit).map((ware) => (
                            <TableRow hover>
                              <TableCell>
                                {ware.name}
                              </TableCell>
                              <TableCell>
                                <IconButton aria-label="edit" onClick={() => {
                                  setItemSelected(ware);
                                  setIsActive(ware.isactive);
                                  handleOpen();
                                }}>
                                  <EditIcon />
                                </IconButton>

                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        : <Typography>No se encontraron resultados</Typography>}
                    </Table>
                    : <LinearProgress />}
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={getWarehouse.quantity}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10]}
              />
            </Card>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h3 id="">ACTUALIZAR DEPOSITO</h3>
                  {itemSelected ?
                    <form>
                      <TextField
                        fullWidth
                        label="Nombre de Depósito"
                        margin="normal"
                        name="name"
                        type="text"
                        variant="outlined"
                        value={itemSelected.name}
                        onChange={e => handleChange(e)}
                      />

                      <Box p={1}>
                        <FormControlLabel
                          value="Activos"
                          control={<Checkbox color="primary" checked={isActive} onChange={event => setIsActive(!isActive)} />}
                          label="Activos"
                          labelPlacement="Activos"
                        />
                      </Box>
                      <Button
                        disabled={itemSelected.name ? false : true}
                        style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmitUpdate}
                      >
                        Actualizar
                      </Button>
                    </form>
                    : null}
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openCreate}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openCreate}>
                <div className={classes.paper}>
                  <h3 id="">CREAR DEPOSITO</h3>
                  <form>
                    <TextField
                      fullWidth
                      label="Nombre de Depósito"
                      margin="normal"
                      name="name"
                      type="text"
                      variant="outlined"
                      value={create ? create.name : null}
                      onChange={e => handleChangeCreate(e)}
                    />
                    <Button
                      disabled={create ? false : true}
                      style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmitCreate}
                    >
                      Crear
                    </Button>
                  </form>
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openDelete}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >

            </Modal>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default OperativeWarehouse;
