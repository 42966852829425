import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_SUBREGION_START,
  GET_SUBREGION_SUCCESS,
  GET_SUBREGION_ERROR,
} from '../../types';
import apiCall from '../../../api/apiGet';

export function* getSubregion({payload}) {
  try {
    const url = `/SubRegion/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_SUBREGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_SUBREGION_ERROR, error});
  }
}

export default function* subregion() {
  yield takeLatest(GET_SUBREGION_START, getSubregion);
}
