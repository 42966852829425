import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
// F4F6F8
// main: rgb(252, 203, 9)
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
      yellow: '#36b34a',
      red: 'rgb(239,0,0)',
      green: '#36b34a',
    },
    primary: {
      contrastText: '#ffffff',
      main: '#000'
    },
    text: {
      primary: '#172b4d',
      secondary: '#747479'
    }
  },
  shadows,
  typography
});

export default theme;
