import axios from 'axios';


const baseURL = 'https://api-comex-web.akeron.net';
//const baseURL = 'http://localhost:65107';
export default (url, method, headers) => axios({
  baseURL,
  method,
  url,
  headers,
});
