import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  Container,
  Fade, FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { lines } from '../redux/actions/lines';
import Autorenew from '@material-ui/icons/Autorenew';
import {getLines} from "../redux/actions/getLines";
import {getFamilies} from "../redux/actions/getFamilies";
import {merchandise} from "../redux/actions/merchandise";
import Select from "react-select";
import {createMerchandise} from "../redux/actions/createMerchandise";
import {updateFamilies} from "../redux/actions/updateFamilies";
import {updateMerchandise} from "../redux/actions/updateMerchandise";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #36b34a',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ProductMerchandise = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [create, setCreate] = useState({ name: null });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [pagInitial, setPagInitial] = useState(0);
  const [pagEnd, setPagEnd] = useState(10);
  const [valueLine, setValueLine] = useState(null);
  const [active, setActive] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [productlineid, setProductlineid] = useState({productlineid: null, name: null});
  const [familie, setFamilie] = useState({id: null, name: null});
  const getMerchandise = useSelector(state => state.merchandise);
  const merchandiseCreate = useSelector(state => state.createMerchandise);
  const merchandiseUpdate = useSelector(state => state.updateMerchandise);
  const getL = useSelector(state => state.getLines);
  const getF = useSelector(state => state.getFamilies);
  const token = useSelector(state => state.login.token);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    setOpenDelete(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleValue = (e) => {
    const {name, value} = e.target;
    setValueLine(value);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setItemSelected((prevState) => ({...prevState, [name]: value}));
  };

  const handleChangeCreate = (e) => {
    const {name, value} = e.target;
    setCreate((prevState) => ({...prevState, [name]: value}));
  };

  const handleSubmitCreate = e => {
    e.preventDefault()
    if (productlineid.productlineid) {
      dispatch(createMerchandise({
        token,
        name: create.name,
        productlineid: productlineid.productlineid,
        productfamilyid: familie.id
      }));
      handleClose();
    }
  }

  const handleSubmitUpdate = e => {
    e.preventDefault()
    dispatch(updateMerchandise({
      token,
      productid: itemSelected.id,
      name: itemSelected.name,
      productlineid: productlineid.productlineid,
      productfamilyid: familie.id,
      isActive
    }));
    handleClose();

  }

  const handleSubmitDelete = e => {
    e.preventDefault()
    // dispatch(loginUser(state));
  }

  const getMerchandiseAll = () => {
    dispatch(merchandise({
      token,
      value: valueLine ? valueLine : ' ',
      pagInitial,
      pagEnd,
      active: active ? 1 : 0
    }))
  }

  useEffect(() => {
    console.log('CARGANDOOOOO')
    setTimeout(() => {
      getMerchandiseAll();
    }, 500);
    setTimeout(() => {
      dispatch(getLines({token}));
    }, 1000);
    setTimeout(() => {
      dispatch(getFamilies({token}));
    }, 1500);
  }, []);

  useEffect(() => {
    console.log(active);
    getMerchandiseAll();
  }, [active, valueLine, pagInitial, pagEnd])

  useEffect(() => {
    if (!merchandiseCreate.loading) {
      setCreate(null);
      setItemSelected({ name: null, itemSelected: null });
      setFamilie({ name: null, id: null })
      getMerchandiseAll();
    }
  }, [merchandiseCreate.loading]);

  useEffect(() => {
    if (!merchandiseUpdate.loading) {
      setCreate(null);
      setItemSelected({ name: null, itemSelected: null });
      setFamilie({ name: null, id: null })
      getMerchandiseAll();
    }
  }, [merchandiseUpdate.loading]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderWidth: '2px',
      color: state.isSelected ? theme.palette.background.yellow : theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      padding: 20,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    control: (styles) => ({
      ...styles,
      width: '100%',
      borderWidth: '0.5px',
      paddingTop: 10,
      paddingBottom: 10,
      boxShadow: theme.palette.text.secondary,
      '&:hover': {
        border: '0.5px theme.palette.text.secondary',
      },
      opacity: '300ms',
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      marginTop: 15,
      marginBottom: 15,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
    <>
      <Helmet>
        <title>Akeron - {t('common:description')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenCreate(true);
                handleOpenCreate();
              }}
            >
              Nueva Mercaderia
            </Button>
            <IconButton color="inherit" onClick={() => getMerchandiseAll()}>
              <Autorenew style={{ color: '#36b34a' }} />
            </IconButton>
            <IconButton color="inherit" onClick={() => dispatch(getFamilies({token}))}>
              <Autorenew style={{ color: '#36b34a' }} />
              <Typography>Familias</Typography>
            </IconButton>
            <IconButton color="inherit" onClick={() => dispatch(getLines({token}))}>
              <Autorenew style={{ color: '#36b34a' }} />
              <Typography>Lineas</Typography>
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }} display="flex" flexDirection="row" alignItems="center">
                  <Box p={1}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      name={'value'}
                      placeholder="Buscar Mercaderia"
                      variant="outlined"
                      value={valueLine}
                      onChange={e => handleValue(e)}
                    />
                  </Box>
                  <Box p={1}>
                    <FormControlLabel
                      value="Activos"
                      control={<Checkbox color="primary" checked={active} onChange={event => setActive(!active)} />}
                      label="Activos"
                      labelPlacement="Activos"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ pt: 2 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ padding: 1, borderWidth: 0 }}>
                  {!getMerchandise.loading ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Producto
                          </TableCell>
                          <TableCell>
                            Linea
                          </TableCell>
                          <TableCell>
                            Familia
                          </TableCell>
                          <TableCell>
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {getMerchandise.quantity > 0 ?
                        <TableBody>
                          {getMerchandise.entries.slice(0, limit).map((mer) => (
                            <TableRow hover>
                              <TableCell>
                                {mer.name}
                              </TableCell>
                              <TableCell>
                                {mer.productlinename}
                              </TableCell>
                              <TableCell>
                                {mer.productfamilyname}
                              </TableCell>
                              <TableCell>
                                <IconButton aria-label="edit" onClick={() => {
                                  setItemSelected(mer);
                                  setProductlineid({productlineid: mer.productlineid, name: mer.productlinename})
                                  setFamilie({id: mer.productfamilyid, name: mer.productfamilyname})
                                  handleOpen();
                                }}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      : <Typography>No se encontraron resultados</Typography>}
                    </Table>
                  : <LinearProgress />}
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={getMerchandise.quantity}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10]}
              />
            </Card>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h3 id="">ACTUALIZAR MERCADERIA</h3>
                  {itemSelected ?
                    <form>
                      <TextField
                        fullWidth
                        label="Nombre de Mercaderia"
                        margin="normal"
                        name="name"
                        type="text"
                        variant="outlined"
                        value={itemSelected.name}
                        onChange={e => handleChange(e)}
                      />
                      <Select
                        name="Name"
                        options={getL.lines ? getL.lines : []}
                        value={productlineid.name}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.productlineid}
                        onChange={(newValue) => {
                          setProductlineid(newValue);
                        }}
                        styles={customStyles}
                        placeholder={productlineid.name ? productlineid.name : 'Seleccione una Linea'}
                      />
                      <Select
                        name="Name"
                        options={getF.families ? getF.families : []}
                        value={familie.name}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        onChange={(newValue) => {
                          setFamilie(newValue);
                        }}
                        styles={customStyles}
                        placeholder={familie.name ? familie.name : 'Seleccione una Familia'}
                      />
                      <Box p={1}>
                        <FormControlLabel
                          value="Activos"
                          control={<Checkbox color="primary" checked={isActive} onChange={event => setIsActive(!isActive)} />}
                          label="Activo"
                          labelPlacement="Activo"
                        />
                      </Box>
                      <Button
                        disabled={itemSelected.name ? false : true}
                        style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmitUpdate}
                      >
                        Actualizar
                      </Button>
                    </form>
                  : null}
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openCreate}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openCreate}>
                <div className={classes.paper}>
                  <h3 id="">CREAR MERCADERIA</h3>
                  <form>
                    <TextField
                      fullWidth
                      label="Nombre de Mercaderia"
                      margin="normal"
                      name="name"
                      type="text"
                      variant="outlined"
                      value={create ? create.name : null}
                      onChange={e => handleChangeCreate(e)}
                    />
                    <Select
                      name="Name"
                      options={getL.lines ? getL.lines : []}
                      value={productlineid.name}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.productlineid}
                      onChange={(newValue) => {
                        setProductlineid(newValue);
                      }}
                      styles={customStyles}
                      placeholder={productlineid.name ? productlineid.name : 'Seleccione una Linea'}
                    />
                    <Select
                      name="Name"
                      options={getF.families ? getF.families : []}
                      value={familie.name}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      onChange={(newValue) => {
                        setFamilie(newValue);
                      }}
                      styles={customStyles}
                      placeholder={familie.name ? familie.name : 'Seleccione una Familia'}
                    />
                    <Button
                      disabled={create ? false : true}
                      style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmitCreate}
                    >
                      Crear
                    </Button>
                  </form>
                </div>
              </Fade>
            </Modal>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ProductMerchandise;
