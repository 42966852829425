import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Autorenew from '@material-ui/icons/Autorenew';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import {typeTransports} from "../redux/actions/typeTransports";
import {createTypeTransports} from "../redux/actions/createTypeTransports";
import {updateTypeTransports} from "../redux/actions/updateTypeTransports";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #36b34a',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

}));

const LogisticsTransport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [pagInitial, setPagInitial] = useState(0);
  const [pagEnd, setPagEnd] = useState(10);
  const [valueLine, setValueLine] = useState(null);
  const [active, setActive] = useState(true);
  const [create, setCreate] = useState({
    name: null,
    capacidad: null,
    high: null,
    width: null,
    long: null,
    high2: null,
    width2: null,
    long2: null });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const token = useSelector(state => state.login.token);
  const getTransports = useSelector(state => state.transports);
  const transportUpdate = useSelector(state => state.updateTransports);
  const transportCreate = useSelector(state => state.createTransports);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    setOpenDelete(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleValue = (e) => {
    const {name, value} = e.target;
    setValueLine(value);
  };

  const handlePageChange = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      setPagInitial(pagInitial + 10);
      setPagEnd(pagEnd + 10);
    } else {
      setPage(newPage);
      setPagInitial(pagInitial - 10);
      setPagEnd(pagEnd - 10);
    }
  };

  const handleChange = (e) => {
    console.log('Item Selected Transports')
    console.log(itemSelected);
    const {name, value} = e.target;
    setItemSelected((prevState) => ({...prevState, [name]: value}));
  };

  const handleChangeCreate = (e) => {
    const {name, value} = e.target;
    setCreate((prevState) => ({...prevState, [name]: value}));
  };

  const handleSubmitCreate = e => {
    e.preventDefault()
    if (create.name) {
      dispatch(createTypeTransports({
        token,
        name: create.name,
        capacidad: create.capacidad,
        high: create.high,
        width: create.width,
        long: create.long,
        high2: create.high2,
        width2: create.width2,
        long2: create.long2
        }));
      handleClose();
    }
  }

  const handleSubmitUpdate = e => {
    e.preventDefault()
    console.log('ITEMSELECTED');
    console.log(itemSelected);
    dispatch(updateTypeTransports({
      token,
      id: itemSelected.id,
      name: itemSelected.name,
      capacidad: itemSelected.capacidad,
      high: itemSelected.high,
      width: itemSelected.width,
      long: itemSelected.long,
      high2: itemSelected.high2,
      width2: itemSelected.width2,
      long2: itemSelected.long2,
      isActive
    }));
    handleClose();

  }

  const handleSubmitDelete = e => {
    e.preventDefault()
    // dispatch(loginUser(state));
  }

  const getTransportsAll = () => {

    dispatch(typeTransports({token, value: valueLine ? valueLine : ' ', pagInitial, pagEnd, active: active ? 1 : 0}))
  }

  useEffect(() => {
    getTransportsAll();
  }, []);

  useEffect(() => {
      getTransportsAll();
  }, [active, valueLine, pagInitial, pagEnd])

  useEffect(() => {
    if (!transportCreate.loading) {
      setCreate(null);
      setItemSelected({ name: null, id: null });
      getTransportsAll();
    }
  }, [transportCreate.loading]);
 useEffect(() => {
    if (!transportUpdate.loading) {
      setCreate(null);
      setItemSelected({ name: null, id: null });
      getTransportsAll();
    }
  }, [transportUpdate.loading]);

  return (
    <>
      <Helmet>
        <title>Akeron - {t('common:description')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenCreate(true);
                handleOpenCreate();
              }}
            >
              Nuevo Camión
            </Button>
            <IconButton color="inherit" onClick={() => getTransportsAll()}>
              <Autorenew style={{ color: '#36b34a' }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }} display="flex" flexDirection="row" alignItems="center">
                  <Box p={1}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      name={'value'}
                      placeholder="Buscar Camion"
                      variant="outlined"
                      value={valueLine}
                      onChange={e => handleValue(e)}
                    />
                  </Box>
                  <Box p={1}>
                    <FormControlLabel
                      value="Activos"
                      control={<Checkbox color="primary" checked={active} onChange={event => setActive(!active)} />}
                      label="Activos"
                      labelPlacement="Activos"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ pt: 2 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ padding: 1, borderWidth: 0 }}>
                  {!getTransports.loading ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Tipos de Camion
                          </TableCell>
                          <TableCell>
                            Capacidad
                          </TableCell>
                          <TableCell>
                            Alto 1
                          </TableCell>
                          <TableCell>
                            Ancho 1
                          </TableCell>
                          <TableCell>
                            Largo 1
                          </TableCell>
                          <TableCell>
                            Alto 2
                          </TableCell>
                          <TableCell>
                            Ancho 2
                          </TableCell>
                          <TableCell>
                            Largo 2
                          </TableCell>
                          <TableCell>
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {getTransports.quantity > 0 ?
                        <TableBody>
                          {getTransports.entries.slice(0, limit).map((transport) => (
                            <TableRow hover>
                              <TableCell>
                                {transport.name}
                              </TableCell>
                              <TableCell>
                                {transport.capacidad}
                              </TableCell>
                              <TableCell>
                                {transport.high}
                              </TableCell>
                              <TableCell>
                                {transport.width}
                              </TableCell>
                              <TableCell>
                                {transport.long}
                              </TableCell>
                              <TableCell>
                                {transport.high2}
                              </TableCell>
                              <TableCell>
                                {transport.width2}
                              </TableCell>
                              <TableCell>
                                {transport.long2}
                              </TableCell>
                              <TableCell>
                                <IconButton aria-label="edit" onClick={() => {
                                  setItemSelected(transport);
                                  handleOpen();
                                }}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        : <Typography>No se encontraron resultados</Typography>}
                    </Table>
                    : <LinearProgress />}
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={getTransports.quantity}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10]}
              />
            </Card>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h3 id="">ACTUALIZAR CAMION</h3>
                  {itemSelected ?
                    <form>
                      <TextField
                        fullWidth
                        label="Nombre"
                        margin="normal"
                        name="name"
                        type="text"
                        variant="outlined"
                        value={itemSelected.name}
                        onChange={e => handleChange(e)}
                      />
                      <TextField
                        fullWidth
                        label="Capacidad"
                        margin="normal"
                        name="capacidad"
                        type="text"
                        variant="outlined"
                        value={itemSelected.capacidad}
                        onChange={e => handleChange(e)}
                      />

                        <div >
                          <TextField
                            //fullWidth
                            label="Altura"
                            paddingRight ="5px"
                            margin="normal"
                            name="high"
                            type="text"
                            variant="outlined"
                            value={itemSelected.high}
                            onChange={e => handleChange(e)}
                          />
                          <TextField
                            //fullWidth
                            label="Altura 2"
                            margin="normal"

                            name="high2"
                            type="text"
                            variant="outlined"
                            value={itemSelected.high2}
                            onChange={e => handleChange(e)}
                          />
                        </div>




                      <div>
                        <TextField
                          //fullWidth
                          label="Ancho"
                          margin="normal"
                          name="width"
                          type="text"
                          variant="outlined"
                          value={itemSelected.width}
                          onChange={e => handleChange(e)}
                        />

                        <TextField
                          //fullWidth
                          label="Ancho 2"
                          margin="normal"
                          name="width2"
                          type="text"
                          variant="outlined"
                          value={itemSelected.width2}
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <div>
                        <TextField
                          //fullWidth
                          label="Largo"
                          margin="normal"
                          name="long"
                          type="text"
                          variant="outlined"
                          value={itemSelected.long}
                          onChange={e => handleChange(e)}
                        />


                        <TextField
                          //fullWidth
                          padding="20px"
                          label="Largo 2"
                          margin="normal"
                          name="long2"
                          type="text"
                          variant="outlined"
                          value={itemSelected.long2}
                          onChange={e => handleChange(e)}
                        />
                      </div>



                      <Box p={1}>
                        <FormControlLabel
                          value="Activos"
                          control={<Checkbox color="primary" checked={isActive} onChange={event => setIsActive(!isActive)} />}
                          label="Activo"
                          labelPlacement="Activo"
                        />
                      </Box>
                      <Button
                        disabled={itemSelected.name ? false : true}
                        style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmitUpdate}
                      >
                        Actualizar
                      </Button>
                    </form>
                    : null}
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openCreate}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openCreate}>
                <div className={classes.paper}>
                  <h3 id="">CREAR CAMION</h3>
                  <form>
                    <TextField
                      fullWidth
                      label="Nombre"
                      margin="normal"
                      name="name"
                      type="text"
                      variant="outlined"
                      value={create ? create.name : null}
                      onChange={e => handleChangeCreate(e)}
                    />
                    <TextField
                      fullWidth
                      label="Capacidad"
                      margin="normal"
                      name="capacidad"
                      type="text"
                      variant="outlined"
                      value={create ? create.capacidad : null}
                      onChange={e => handleChangeCreate(e)}
                    />
                    <div>
                      <TextField
                        //fullWidth
                        label="Altura"
                        margin="normal"
                        name="high"
                        type="text"
                        variant="outlined"
                        value={create ? create.high : null}
                        onChange={e => handleChangeCreate(e)}
                      />
                      <TextField
                        //fullWidth
                        label="Altura 2"
                        margin="normal"
                        name="high2"
                        type="text"
                        variant="outlined"
                        value={create ? create.high2 : null}
                        onChange={e => handleChangeCreate(e)}
                      />
                    </div>
                    <div>
                      <TextField
                        //fullWidth
                        label="Ancho"
                        margin="normal"
                        name="width"
                        type="text"
                        variant="outlined"
                        value={create ? create.width : null}
                        onChange={e => handleChangeCreate(e)}
                      />
                      <TextField
                        //fullWidth
                        label="Ancho 2"
                        margin="normal"
                        name="width2"
                        type="text"
                        variant="outlined"
                        value={create ? create.width2 : null}
                        onChange={e => handleChangeCreate(e)}
                      />
                    </div>
                    <div>
                      <TextField
                        //fullWidth
                        label="Largo"
                        margin="normal"
                        name="long"
                        type="text"
                        variant="outlined"
                        value={create ? create.long : null}
                        onChange={e => handleChangeCreate(e)}
                      />


                      <TextField
                        //fullWidth
                        label="Largo 2"
                        margin="normal"
                        name="long2"
                        type="text"
                        variant="outlined"
                        value={create ? create.long2 : null}
                        onChange={e => handleChangeCreate(e)}
                      />
                    </div>



                    <Button
                      disabled={create ? false : true}
                      style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmitCreate}
                    >
                      Crear
                    </Button>
                  </form>
                </div>
              </Fade>
            </Modal>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default LogisticsTransport;
