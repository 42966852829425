import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton, Menu, MenuItem,
  Toolbar, Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import TranslateIcon from '@material-ui/icons/Translate';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch, useSelector } from 'react-redux';

import Logo from './Logo';
import { loginUser } from '../redux/actions/login';
import { setLanguage } from '../redux/actions/language';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state => state.language));
  const [anchorEl, setAnchorEl] = useState(null);
  const [alias, setAlias] = useState(language.language === 'es' ? 'Español' : 'English');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(setLanguage(alias === 'Español' ? 'es' : 'en'))
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(loginUser('LOGOUT'))
    navigate('/login');
  };

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton
            color="inherit"
            onClick={handleClick}
            direction="row"
            container
            alignContent="center"
            alignItems="center"
          >
            <TranslateIcon style={{ color: '#edeef1', marginRight: 1 }} />
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              style={{ color: '#edeef1' }}
            >
              {alias}
            </Typography>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '15ch',
              },
            }}
          >
            <MenuItem onClick={() => {
              handleClose();
              dispatch(setLanguage('es'))
              setAlias('Español');
            }}
            >
              Español
            </MenuItem>
            <MenuItem onClick={() => {
              handleClose();
              dispatch(setLanguage('en'))
              setAlias('English');
            }}
            >
              English
            </MenuItem>
          </Menu>
          <IconButton color="inherit" onClick={() => handleLogout()}>
            <InputIcon style={{ color: '#edeef1' }} />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon style={{ color: '#edeef1' }} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
