import axios from 'axios';


const baseURL = 'https://api-comex-web.akeron.net';
//const baseURL = 'http://localhost:65107';
const config = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export default (url, method, data, headers) => axios({
  baseURL,
  method,
  url,
  headers,
  data,
  config,
});
