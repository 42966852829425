import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Autorenew from '@material-ui/icons/Autorenew';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import {typeDocuments} from "../redux/actions/typeDocuments";
import {createTypeDocument} from "../redux/actions/createTypeDocument";
import {updateTypeDocument} from "../redux/actions/updateTypeDocument";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #36b34a',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

}));

const LogisticsTypeDocument = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [pagInitial, setPagInitial] = useState(0);
  const [pagEnd, setPagEnd] = useState(10);
  const [valueLine, setValueLine] = useState(null);
  const [active, setActive] = useState(true);
  const [create, setCreate] = useState({name: null});
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const token = useSelector(state => state.login.token);
  const getTypeDocument = useSelector(state => state.typeDocuments);
  const typeDocumentUpdate = useSelector(state => state.updateTypeDocument);
  const typeDocumentCreate = useSelector(state => state.createTypeDocument);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    setOpenDelete(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleValue = (e) => {
    const {name, value} = e.target;
    setValueLine(value);
  };

  const handlePageChange = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      setPagInitial(pagInitial + 10);
      setPagEnd(pagEnd + 10);
    } else {
      setPage(newPage);
      setPagInitial(pagInitial - 10);
      setPagEnd(pagEnd - 10);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setItemSelected((prevState) => ({...prevState, [name]: value}));
  };

  const handleChangeCreate = (e) => {
    const {name, value} = e.target;
    setCreate((prevState) => ({...prevState, [name]: value}));
  };

  const handleSubmitCreate = e => {
    e.preventDefault()
    if (create.name) {
      dispatch(createTypeDocument({
        token,
        name: create.name
      }));
      handleClose();
    }
  }

  const handleSubmitUpdate = e => {
    e.preventDefault()
    dispatch(updateTypeDocument({
      token,
      id: itemSelected.id,
      name: itemSelected.name,
      isActive
    }));
    handleClose();

  }

  const handleSubmitDelete = e => {
    e.preventDefault()
    // dispatch(loginUser(state));
  }

  const getTypesDocumentsAll = () => {

    dispatch(typeDocuments({token, value: valueLine ? valueLine : ' ', pagInitial, pagEnd, active: active ? 1 : 0}))
  }

  useEffect(() => {
    getTypesDocumentsAll();
  }, []);

  useEffect(() => {
    getTypesDocumentsAll();
  }, [active, valueLine, pagInitial, pagEnd])

  useEffect(() => {
    if (!typeDocumentCreate.loading) {
      setCreate(null);
      setItemSelected({ name: null, id: null });
      getTypesDocumentsAll();
    }
  }, [typeDocumentCreate.loading]);

  useEffect(() => {
    if (!typeDocumentUpdate.loading) {
      setCreate(null);
      setItemSelected({ name: null, id: null });
      getTypesDocumentsAll();
    }
  }, [typeDocumentUpdate.loading]);

  return (
    <>
      <Helmet>
        <title>Akeron - {t('common:description')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenCreate(true);
                handleOpenCreate();
              }}
            >
              Nuevo Tipo de Documento
            </Button>
            <IconButton color="inherit" onClick={() => getTypesDocumentsAll()}>
              <Autorenew style={{ color: '#36b34a' }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }} display="flex" flexDirection="row" alignItems="center">
                  <Box p={1}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      name={'value'}
                      placeholder="Buscar Tipo"
                      variant="outlined"
                      value={valueLine}
                      onChange={e => handleValue(e)}
                    />
                  </Box>
                  <Box p={1}>
                    <FormControlLabel
                      value="Activos"
                      control={<Checkbox color="primary" checked={active} onChange={event => setActive(!active)} />}
                      label="Activos"
                      labelPlacement="Activos"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ pt: 2 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ padding: 1, borderWidth: 0 }}>
                  {!getTypeDocument.loading ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Nombre
                          </TableCell>
                          <TableCell>
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {getTypeDocument.quantity > 0 ?
                        <TableBody>
                          {getTypeDocument.entries.slice(0, limit).map((type) => (
                            <TableRow hover>
                              <TableCell>
                                {type.name}
                              </TableCell>
                              <TableCell>
                                <IconButton aria-label="edit" onClick={() => {
                                  setItemSelected(type);
                                  handleOpen();
                                }}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        : <Typography>No se encontraron resultados</Typography>}
                    </Table>
                    : <LinearProgress />}
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={getTypeDocument.quantity}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10]}
              />
            </Card>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h3 id="">ACTUALIZAR TIPO</h3>
                  {itemSelected ?
                    <form>
                      <TextField
                        fullWidth
                        label="Nombre"
                        margin="normal"
                        name="name"
                        type="text"
                        variant="outlined"
                        value={itemSelected.name}
                        onChange={e => handleChange(e)}
                      />
                      <Box p={1}>
                        <FormControlLabel
                          value="Activos"
                          control={<Checkbox color="primary" checked={isActive} onChange={event => setIsActive(!isActive)} />}
                          label="Activo"
                          labelPlacement="Activo"
                        />
                      </Box>
                      <Button
                        disabled={itemSelected.name ? false : true}
                        style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmitUpdate}
                      >
                        Actualizar
                      </Button>
                    </form>
                    : null}
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openCreate}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openCreate}>
                <div className={classes.paper}>
                  <h3 id="">CREAR TIPO DE DOCUMENTO</h3>
                  <form>
                    <TextField
                      fullWidth
                      label="Nombre"
                      margin="normal"
                      name="name"
                      type="text"
                      variant="outlined"
                      value={create ? create.name : null}
                      onChange={e => handleChangeCreate(e)}
                    />
                    <Button
                      disabled={create ? false : true}
                      style={{ backgroundColor: '#36b34a', color: '#FFF' }}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmitCreate}
                    >
                      Crear
                    </Button>
                  </form>
                </div>
              </Fade>
            </Modal>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default LogisticsTypeDocument;
