import Akeron from '../assets/img/akeron.png'
const Logo = (props) => (
  <img
    alt="Akeron"
    width="175"
    height="47"
    src={Akeron}
    {...props}
  />
);

export default Logo;
