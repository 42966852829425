import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_LINE_ERROR,
  CREATE_LINE_START,
  CREATE_LINE_SUCCESS,
} from '../types';
import apiCall from '../../api';

export function* line({payload}) {
  try {
    const url = '/ProductLine/Create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {name: payload.name}
    console.log(data);
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_LINE_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: CREATE_LINE_ERROR, error});
  }
}

export default function* createLine() {
  yield takeLatest(CREATE_LINE_START, line);
}
