import {takeLatest, put, call} from 'redux-saga/effects';
import {
  GET_REGION_START,
  GET_REGION_SUCCESS,
  GET_REGION_ERROR,
} from '../types';
import apiCall from '../../api/apiGet';

export function* getRegion({payload}) {
  try {
    const url = `/Region/GetList/${payload.value}/${payload.pagInitial}/${payload.pagEnd}/${payload.active}`;
    const method = 'GET';
    console.log(url);
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    console.log(results);
    yield put({type: GET_REGION_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: GET_REGION_ERROR, error});
  }
}

export default function* region() {
  yield takeLatest(GET_REGION_START, getRegion);
}
