import {
  CREATE_WAREHOUSE_ERROR,
  CREATE_WAREHOUSE_START,
  CREATE_WAREHOUSE_SUCCESS
} from '../../types';

export default function (state, action) {
  switch (action.type) {
    case CREATE_WAREHOUSE_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_WAREHOUSE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}
