import { all, take } from 'redux-saga/effects';
import {REHYDRATE} from 'redux-persist/lib/constants';

import login from './login';
import language from './language';
import getOrganizations from './getOrganizations';
import lines from './lines';
import createLine from './createLine';
import updateLine from './updateLine';
import deleteLine from './deleteLine';
import createFamilies from './createFamilies';
import updateFamilies from './updateFamilies';
import deleteFamilies from './deleteFamilies';
import families from './families';
import createCustoms from "./createCustoms";
import updateCustoms from "./updateCustoms";
import deleteCustoms from "./deleteCustoms";
import customs from "./customs";
import channels from "./channels";
import createChannels from "./createChannels";
import deleteChannels from "./deleteChannels";
import updateChannels from "./updateChannels";
import fiscalwarehouse from "./fiscalwarehouse";
import updateFiscalWarehouse from "./updateFiscalWarehouse";
import createFiscalWarehouse from "./createFiscalWarehouse";
import terminal from "./terminal";
import createTerminal from "./createTerminal";
import updateTerminal from "./updateTerminal";
import containers from "./containers";
import createContainers from "./createContainers";
import updateContainers from "./updateContainers";
import getLines from "./getLines";
import getFamilies from "./getFamilies";
import merchandise from "./merchandise";
import createMerchandise from "./createMerchandise";
import updateMerchandise from "./updateMerchandise";
import typeTransports from "./typeTransports";
import createTransports from "./createTransports";
import updateTransports from "./updateTransports";
import typeDocuments from "./typeDocuments";
import createTypeDocument from "./createTypeDocuments";
import updateTypeDocument from "./updateTypeDocuments";
import getRegion from "./getRegion";
import region from "./region";
import createRegion from "./createRegion";
import updateRegion from "./updateRegion";
import getPorts from "./LogisticsPort/getPorts";
import port from "./LogisticsPort/port";
import createPort from "./LogisticsPort/createPort";
import updatePort from "./LogisticsPort/updatePort";
import warehouse from "./OperativeWarehouse/warehouse";
import createWarehouse from "./OperativeWarehouse/createWarehouse";
import updateWarehouse from "./OperativeWarehouse/updateWarehouse";
import destination from "./OperativeDestination/destination";
import createDestination from "./OperativeDestination/createDestination";
import updateDestination from "./OperativeDestination/updateDestination";
import driver from "./OperativeDriver/driver";
import createDriver from "./OperativeDriver/createDriver";
import updateDriver from "./OperativeDriver/updateDriver";
import operativeTransport from "./OperativeTransport/operativeTransport"
import createOperativeTransport from "./OperativeTransport/createOperativeTransport";
import updateOperativeTransport from "./OperativeTransport/updateOperativeTransport";
import subregion from "./ConfigurationSubregion/subregion";
import createSubregion from "./ConfigurationSubregion/createSubregion";
import updateSubregion from "./ConfigurationSubregion/updateSubregion";
import getSubregion from "./ConfigurationSubregion/getSubregion";
import operativeArea from "./ConfigurationOperativeArea/operativeArea"
import createOperativeArea from "./ConfigurationOperativeArea/createOperativeArea";
import updateOperativeArea from "./ConfigurationOperativeArea/updateOperativeArea";
import getOperativeArea from "./ConfigurationOperativeArea/getOperativeArea";
import getOrganizationTypes from "./ConfigurationOrganizationTypes/getOrganizationTypes";



export default function* rootSaga() {
  yield take(REHYDRATE);
  yield all([
    login(),
    language(),
    getOrganizations(),
    lines(),
    createLine(),
    updateLine(),
    deleteLine(),
    families(),
    createFamilies(),
    updateFamilies(),
    deleteFamilies(),
    customs(),
    createCustoms(),
    updateCustoms(),
    deleteCustoms(),
    channels(),
    createChannels(),
    deleteChannels(),
    updateChannels(),
    fiscalwarehouse(),
    createFiscalWarehouse(),
    updateFiscalWarehouse(),
    terminal(),
    createTerminal(),
    updateTerminal(),
    containers(),
    createContainers(),
    updateContainers(),
    getLines(),
    getFamilies(),
    merchandise(),
    createMerchandise(),
    updateMerchandise(),
    typeTransports(),
    createTransports(),
    updateTransports(),
    typeDocuments(),
    createTypeDocument(),
    updateTypeDocument(),
    getRegion(),
    region(),
    createRegion(),
    updateRegion(),
    getPorts(),
    port(),
    createPort(),
    updatePort(),
    warehouse(),
    createWarehouse(),
    updateWarehouse(),
    destination(),
    createDestination(),
    updateDestination(),
    driver(),
    createDriver(),
    updateDriver(),
    operativeTransport(),
    createOperativeTransport(),
    updateOperativeTransport(),
    subregion(),
    createSubregion(),
    updateSubregion(),
    getSubregion(),
    operativeArea(),
    createOperativeArea(),
    updateOperativeArea(),
    getOperativeArea(),
    getOrganizationTypes(),
  ]);
}
