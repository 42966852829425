import {takeLatest, put, call} from 'redux-saga/effects';
import {
  CREATE_TRANSPORT_ERROR,
  CREATE_TRANSPORT_START,
  CREATE_TRANSPORT_SUCCESS,
} from '../types';
import apiCall from '../../api';

export function* create({payload}) {
  try {
    const url = '/CamionType/create';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      name: payload.name,
      capacidad: payload.capacidad,
      high: payload.high,
      width: payload.width,
      long: payload.long,
      high2: payload.high2,
      width2: payload.width2,
      long2: payload.long2

    }
    const results = yield call(apiCall, url, method, data, headers);
    yield put({type: CREATE_TRANSPORT_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error);
    yield put({type: CREATE_TRANSPORT_ERROR, error});
  }
}

export default function* createTransports() {
  yield takeLatest(CREATE_TRANSPORT_START, create);
}
